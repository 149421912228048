import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const Code = makeShortcode("Code");
const Footnote = makeShortcode("Footnote");
const CodeBlock = makeShortcode("CodeBlock");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` is built for robust certificate management in distributed systems. As
with any entity in your infrastructure, running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` effectively in
production requires some knowledge of its strengths and limitations. This
document addresses the important production considerations that operators
should know about when running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` as a certificate authority server.`}</p>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#securing-your-pki"
        }}>{`Good Security Practices`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#safeguard-your-root-and-intermediate-keys"
            }}>{`Safeguard Your Root And Intermediate Keys`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-strong-passwords-and-store-them-well"
            }}>{`Use Strong Passwords and Store Them Well`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#avoid-storing-passwords-in-environment-variables"
            }}>{`Avoid Storing Passwords In Environment Variables`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#delete-your-default-provisioner"
            }}>{`Delete Your Default Provisioner`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-short-lived-certificates"
            }}>{`Use Short-Lived Certificates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#enable-active-revocation-on-your-intermediate-ca"
            }}>{`Enable Active Revocation On Your Intermediate CA`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-templates-with-care"
            }}>{`Use Templates With Care`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#create-a-service-user-to-run-step-ca"
            }}>{`Create a Service User to Run `}<inlineCode parentName="a">{`step-ca`}</inlineCode></a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#running-step-ca-as-a-daemon"
        }}>{`Running `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` as a Daemon`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#high-availability"
        }}>{`High Availability`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#load-balancing-or-proxying-step-ca-traffic"
        }}>{`Load balancing or proxying `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` traffic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#automate-x509-certificate-lifecycle-management"
        }}>{`Automate X.509 Certificate Lifecycle Management`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#x509-certificate-revocation"
        }}>{`X.509 Certificate Revocation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sane-cryptographic-defaults"
        }}>{`Sane Cryptographic Defaults`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#tokens"
            }}>{`Tokens`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#key-types-and-ciphers"
            }}>{`Key Types and Ciphers`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#x509-certificates"
            }}>{`X.509 Certificates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#tls-defaults"
            }}>{`TLS Defaults`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`Good Security Practices`}</h2>
    <p>{`In this section we recommend a few best practices when it comes to running, deploying, and managing your own online certificate authority server and PKI.
Security is a moving target.
We expect our recommendations to change and evolve as well.`}</p>
    <h3>{`Safeguard Your Root And Intermediate CA Keys`}</h3>
    <p>{`When you initialize a two-tier CA, two private keys are generated:
one intermediate private key, and one root private key.
It is very important that these private keys be kept secret.`}</p>
    <p>{`The intermediate key is used by the CA to sign certificates. The root key is not needed for day-to-day CA operation and should be stored offline.
The keys can be generated on an air-gapped device or on a Hardware Security Module (HSM).`}</p>
    <p>{`Here's an example key protection strategy for a high-security production PKI.`}</p>
    <p>{`In this example, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` acts as a subordinate CA to an offline root CA.`}</p>
    <ol>
      <li parentName="ol">{`Generate a root CA (private key and certificate) on a `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Hardware_security_module"
        }}>{`Hardware Security Module (HSM)`}</a>{` or air-gapped device that is kept in "cold storage", off the internet.
HSMs are ideal for storing private keys and performing signing operations securely.
For durability, keep at least two copies of your root key, in separate locations.`}</li>
      <li parentName="ol">{`Generate intermediate key(s) on a separate, online cloud HSM or in a key management service (KMS) that will be used by the CA for signing operations in production`}</li>
      <li parentName="ol">{`Generate Certificate Signing Requests (CSRs) for your intermediate CA(s)`}</li>
      <li parentName="ol">{`Sign the generated CSR using the root HSM`}</li>
      <li parentName="ol">{`Configure `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to use the signed root and intermediate certificates`}</li>
      <li parentName="ol">{`Configure `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to access the cloud HSM or KMS intermediate key for online signing operations`}</li>
    </ol>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration#cryptographic-protection"
      }}>{`Cryptographic Protection`}</a>{` section of our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration"
      }}>{`Configuration Guide`}</a>{` to learn more about your options for using HSMs or cloud KMS with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <h3>{`Use Strong Passwords and Store Them Well`}</h3>
    <p>{`When you `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started/#initialize-your-certificate-authority"
      }}>{`initialize your PKI`}</a>{`,
the root and intermediate private keys will be encrypted with the same password.`}</p>
    <p>{`Use a password manager to generate random passwords,
or let `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` generate a strong password for you.`}</p>
    <p>{`After initializing your CA,
we recommend that you immediately change the password for the intermediate CA private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step crypto change-pass $(step path)/secrets/intermediate_ca_key
`}</code></pre>
    <p>{`You'll use this new intermediate key password to start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Never use your password in a command line argument.</AlertTitle>
  <div>
    To keep sensitive values from leaking into <Code mdxType="Code">.bash_history</Code> and system state, put
    your intermediate key password into a file by itself. Then use the
    <Code mdxType="Code">--password-file</Code> flag when calling <Code mdxType="Code">step-ca</Code>.
  </div>
    </Alert>
    <p>{`Once you've changed the intermediate private key password,
you should never have to use the root private key password again.
So, then what should you do with it?`}</p>
    <p>{`Bury it in a cave high in the mountains.`}</p>
    <p>{`Or, store it in a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/List_of_password_managers"
      }}>{`password manager`}</a>{` or secrets manager.
There are many to choose from and the choice will depend on the risk & security profile of your organization.`}</p>
    <p>{`In addition to using a password manager to store all passwords
(private key, provisioner password, etc.) we recommend using a threshold cryptography algorithm like
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Shamir's_Secret_Sharing"
      }}>{`Shamir's Secret Sharing`}</a>{`
to divide the root private key password across a handful of trusted parties.`}</p>
    <h3>{`Avoid Storing Passwords in Environment Variables`}</h3>
    <p><inlineCode parentName="p">{`systemd`}</inlineCode>{` discourages using the environment for secrets
because it doesn't consider it secure and exposes a unit's environment over dbus. From
`}<a parentName="p" {...{
        "href": "https://www.man7.org/linux/man-pages/man5/systemd.exec.5.html#ENVIRONMENT"
      }}>{`systemd.exec(5)`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note that environment variables are not suitable for passing secrets (such as passwords, key material, …) to service processes.
Environment variables set for a unit are exposed to unprivileged clients via D-Bus IPC, and generally not understood as being data that requires protection.
Moreover, environment variables are propagated down the process tree,
including across security boundaries (such as setuid/setgid executables),
and hence might leak to processes that should not have access to the secret data.`}</p>
    </blockquote>
    <p>{`For some isolated environments, we could see an argument for the convenience of an environment variable.
Even then, there can be subtle issues.
For example, anyone with access to the Docker daemon can view all of the environment variables of running Docker containers, using `}<inlineCode parentName="p">{`docker inspect`}</inlineCode>{`.`}</p>
    <p>{`For posterity, however, if you've secured your environment and rely on it for secrets, there is a way to pass a password into `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` from an environment variable in Bash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step-ca --password-file <(echo -n "$STEP_CA_PASSWORD") $(step path)/config/ca.json
`}</code></pre>
    <p>{`This method is known as `}<a parentName="p" {...{
        "href": "https://www.gnu.org/software/bash/manual/html_node/Process-Substitution.html"
      }}>{`Bash Process Subsitution`}</a>{`,
and on most systems the password will not appear in `}<inlineCode parentName="p">{`ps`}</inlineCode>{` output.
However, `}<strong parentName="p">{`this approach is not recommended`}</strong>{` simply because it's so difficult to ensure security with environment variables.`}</p>
    <p>{`See our blog `}<a href="/blog/command-line-secrets/">{`How to Handle Secrets on the Command Line`}</a>{` for an in-depth exploration of this topic.`}</p>
    <h3>{`Replace Your Default Provisioner`}</h3>
    <p>{`When you `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started/#initialize-your-certificate-authority"
      }}>{`initialize your PKI`}</a>{`,
a default `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner will be created.
If you're not going to use this provisioner,
we recommend deleting it.`}</p>
    <p>{`If you `}<em parentName="p">{`are`}</em>{` going to use this provisioner,
secure it with a different password than your CA's signing keys.
You can do this by passing separate `}<inlineCode parentName="p">{`--password-file`}</inlineCode>{` and `}<inlineCode parentName="p">{`--provisioner-password-file`}</inlineCode>{` files when running `}<inlineCode parentName="p">{`step ca init`}</inlineCode>{`.`}</p>
    <p>{`See the section on `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#provisioner-management"
      }}>{`managing provisioners`}</a>{`.`}</p>
    <h3>{`Use Short-Lived Certificates`}</h3>
    <p>{`We recommend the following:`}</p>
    <ul>
      <li parentName="ul">{`User certificates should have the lifespan of a mayfly: about a day or less`}<sup parentName="li" {...{
          "id": "fnref-1"
        }}><a parentName="sup" {...{
            "href": "#fn-1",
            "className": "footnote-ref"
          }}>{`1`}</a></sup>{`.`}</li>
      <li parentName="ul">{`Host or service account certificates should have a lifetime of one month or less.`}</li>
    </ul>
    <p>{`Certificates from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` expire in 24 hours by default.
We made it easy for you to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/renewal"
      }}>{`automate the renewal of your certificates`}</a>{` using the `}<inlineCode parentName="p">{`step`}</inlineCode>{` command.
Carpe diem!`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration#basic-configuration-options"
      }}>{`configure certificate lifetimes`}</a>{` in the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` file. `}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
    Short-lived certificates are not a replacement for active revocation using certificate revocation lists (CRL) or Online Certificate Status Protocol (OCSP).
	Automatic active revocation is not available in <Code mdxType="Code">step-ca</Code>, but you can manually manage a CRL for your CA certificates. See below.
  </div>
    </Alert>
    <Footnote id="fn-1" marker="1" mdxType="Footnote">
One mayfly species, <i>dolania americana</i>, lives for five minutes or less!
So do some certificates.
But it can be difficult to operationalize such short-lived certificates.
    </Footnote>
    <h3>{`Enable Active Revocation On Your Intermediate CA`}</h3>
    <p>{`The value of a two-tiered PKI is that you can add your root CA certificate to the certificate trust store on all of your nodes, and store your root private key completely offline.
A leaf certificate signed by the CA always comes in a bundle that contains the intermediate CA certificate alongside the leaf certificate.
With this bundle, any client that trusts your root CA can verify the complete chain of trust.`}</p>
    <p>{`Now, what if one day your intermediate CA key is compromised?
You could issue a new intermediate using your root CA key, but your old intermediate has a 10 year validity period!
So, you're stuck having to rotate your root CA too, and that's a much bigger project because you have to distribute the new root certificate to everyone and ensure the old one is no longer trusted.
To avoid this scenario, you can use `}<em parentName="p">{`active revocation`}</em>{` on your intermediate CA certificate,
making it possible to immediately revoke a compromised intermediate.`}</p>
    <p>{`While `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` doesn't directly support active revocation mechanisms like Certificate Revocation Lists (CRLs) or the Online Certificate Status Portocol (OCSP),
you can independently manage your own CRL if you like.`}</p>
    <h4>{`Create an intermediate CA with a CRL distribution endpoint`}</h4>
    <p>{`Let's make it possible to revoke your intermediate CA down the road if necessary.
This setup is more complex than the default `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` PKI, but it offers an insurance policy for a compromised intermediate CA.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create an intermediate CA that includes a CRL endpoint. Save the following template to `}<inlineCode parentName="p">{`intermediate.tpl`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
        "subject": {{ toJson .Subject }},
        "keyUsage": ["certSign", "crlSign"],
        "basicConstraints": {
                "isCA": true,
                "maxPathLen": 0
        },
        "crlDistributionPoints":
                ["http://crl.example.com/crl/ca.crl"]
        }
}
`}</code></pre>
        <p parentName="li">{`You'll need this template to manually create your intermediate CA.
The CRL endpoint here should be an HTTP URL; the CRL file itself is signed.
The CRL will be a static file, so you you might choose an object storage or CDN endpoint here.`}</p>
        <p parentName="li">{`Use the template to create your intermediate CA. You will need your root CA certificate and key:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`$ step certificate create "Example Intermediate CA" \\
     $(step path)/certs/intermediate_ca.crt \\
     $(step path)/secrets/intermediate_ca_key \\
     --template intermediate.tpl \\
     --ca $(step path)/certs/root_ca.crt \\
     --ca-key $(step path)/secrets/root_ca_key \\
     --not-after 87660h
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create an empty CRL file and sign it with your root CA key:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`cat <<EOF > openssl.conf
[ ca ]
default_ca = CA_default

[ CA_default ]
default_crl_days = 30
database = index.txt
default_md = sha256
EOF
touch index.txt
openssl ca \\
    -config openssl.conf \\
    -gencrl \\
    -keyfile $(step path)/secrets/root_ca_key \\
    -cert $(step path)/certs/root_ca.crt \\
    -out ca.crl.pem
openssl crl \\
    -inform PEM \\
    -in ca.crl.pem \\
    -outform DER \\
    -out ca.crl
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Upload the DER-formatted `}<inlineCode parentName="p">{`ca.crl`}</inlineCode>{` file to the distribution point URL you specified in the template.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Finally, configure your `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server to use the intermediate CA you created.`}</p>
      </li>
    </ol>
    <Alert severity="warning" mdxType="Alert">
  <div>
	Your CRL will expire, so you will need to generate and push a new empty CRL file regularly. We recommend updating the CRL once two-thirds of its lifetime has elapsed. Configuring automated CRL renewal is beyond the scope of this document.
  </div>
    </Alert>
    <h4>{`Revoke A Certificate`}</h4>
    <p>{`To revoke a certificate, add it to the `}<inlineCode parentName="p">{`index.txt`}</inlineCode>{` file before regenerating the CRL file. The format for this CRL database file is:`}</p>
    <ul>
      <li parentName="ul">{`One certificate per line`}</li>
      <li parentName="ul">{`Each line is tab-delimited`}</li>
      <li parentName="ul">{`The tab-delimited fields are:`}
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Entry type.`}</strong>{` May be `}<inlineCode parentName="li">{`V`}</inlineCode>{` (valid), `}<inlineCode parentName="li">{`R`}</inlineCode>{` (revoked) or `}<inlineCode parentName="li">{`E`}</inlineCode>{` (expired).
An expired certificate may have the type `}<inlineCode parentName="li">{`V`}</inlineCode>{` because the type has not been updated.
`}<inlineCode parentName="li">{`openssl ca updatedb`}</inlineCode>{` does such an update.`}</li>
          <li parentName="ol"><strong parentName="li">{`Expiration datetime.`}</strong>{` Format is `}<inlineCode parentName="li">{`yymmddHHMMSSZ`}</inlineCode>{`.`}</li>
          <li parentName="ol"><strong parentName="li">{`Revokation datetime`}</strong>{` and `}<strong parentName="li">{`optional revocation reason`}</strong>{`. Must be set for any entry of the type `}<inlineCode parentName="li">{`R`}</inlineCode>{`. Format is `}<inlineCode parentName="li">{`yymmddHHMMSSZ[,reason]`}</inlineCode>{`.`}</li>
          <li parentName="ol"><strong parentName="li">{`Certificate serial number`}</strong>{` in uppercase hexidecimal, eg. `}<inlineCode parentName="li">{`804A72D941DB451A0123BA4706446D1F`}</inlineCode>{`.`}</li>
          <li parentName="ol"><strong parentName="li">{`File name`}</strong>{` This doesn't seem to be used, ever, so use the value `}<inlineCode parentName="li">{`unknown`}</inlineCode>{`.`}</li>
          <li parentName="ol"><strong parentName="li">{`Certificate subject`}</strong>{` eg. `}<inlineCode parentName="li">{`CN=Test Intermediate CA,O=Smallstep Labs\\, Inc`}</inlineCode></li>
        </ol>
      </li>
    </ul>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://pki-tutorial.readthedocs.io/en/latest/cadb.html"
      }}>{`OpenSSL PKI Tutorial`}</a></p>
    <h3>{`Use Templates With Care`}</h3>
    <p>{`Because certificate templates add a lot of flexibility to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`,
they can be a source of subtle vulnerabilities in your PKI.
If you use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/templates"
      }}>{`custom certificate templates`}</a>{`, be sure they are tightly restricted for your use case.
Use extreme caution when referencing user-supplied data marked as `}<inlineCode parentName="p">{`Insecure`}</inlineCode>{` in templates.`}</p>
    <h3>{`Create a Service User to Run `}<inlineCode parentName="h3">{`step-ca`}</inlineCode></h3>
    <p>{`Make sure that the configuration folders, private keys, and password file used by the CA are `}<em parentName="p">{`only`}</em>{`
accessible by this user. If you're running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` on port 443, you'll need the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` binary
to be able to bind to that port. See `}<a parentName="p" {...{
        "href": "#running-step-ca-as-a-daemon"
      }}>{`Running `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` as a Daemon`}</a>{` for details.`}</p>
    <h2>{`Running `}<inlineCode parentName="h2">{`step-ca`}</inlineCode>{` as a Daemon`}</h2>
    <p>{`Note: `}<em parentName="p">{`This section requires a Linux OS running `}<inlineCode parentName="em">{`systemd`}</inlineCode>{` version 245 or greater.`}</em></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add a service user for the CA.`}</p>
        <p parentName="li">{`The service user will only be used by `}<inlineCode parentName="p">{`systemd`}</inlineCode>{` to manage the CA. Run:`}</p>
        <CodeBlock language="shell-session" copytext="sudo useradd --system --home /etc/step-ca --shell /bin/false step" mdxType="CodeBlock">
          {`$ sudo useradd --system --home /etc/step-ca --shell /bin/false step`}
        </CodeBlock>
        <p parentName="li">{`If your CA will bind to port 443, the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` binary will need to be
given low port-binding capabilities:`}</p>
        <CodeBlock language="shell-session" copyText="sudo setcap CAP_NET_BIND_SERVICE=+eip $(which step-ca)" mdxType="CodeBlock">
          {`$ sudo setcap CAP_NET_BIND_SERVICE=+eip $(which step-ca)`}
        </CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Move your CA configuration into a system-wide location. Run:`}</p>
        <CodeBlock language="shell-session" copyText="sudo mv $(step path) /etc/step-ca" mdxType="CodeBlock">
          {`$ sudo mv $(step path) /etc/step-ca`}
        </CodeBlock>
        <p parentName="li">{`Make sure your CA password is located in `}<inlineCode parentName="p">{`/etc/step-ca/password.txt`}</inlineCode>{`,
so that it can be read upon server startup.`}</p>
        <p parentName="li">{`You'll also need to edit the file `}<inlineCode parentName="p">{`/etc/step-ca/config/defaults.json`}</inlineCode>{` to reflect the new path.`}</p>
        <p parentName="li">{`Set the `}<inlineCode parentName="p">{`step`}</inlineCode>{` user as the owner of your CA configuration directory:`}</p>
        <CodeBlock language="shell-session" copytext="sudo chown -R step:step /etc/step-ca" mdxType="CodeBlock">
          {`$ sudo chown -R step:step /etc/step-ca`}
        </CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a `}<inlineCode parentName="p">{`systemd`}</inlineCode>{` unit file.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell-session"
          }}>{`$ sudo touch /etc/systemd/system/step-ca.service
`}</code></pre>
        <p parentName="li">{`Add the following contents:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-ini"
          }}>{`[Unit]
Description=step-ca service
Documentation=https://smallstep.com/docs/step-ca
Documentation=https://smallstep.com/docs/step-ca/certificate-authority-server-production
After=network-online.target
Wants=network-online.target
StartLimitIntervalSec=30
StartLimitBurst=3
ConditionFileNotEmpty=/etc/step-ca/config/ca.json
ConditionFileNotEmpty=/etc/step-ca/password.txt

[Service]
Type=simple
User=step
Group=step
Environment=STEPPATH=/etc/step-ca
WorkingDirectory=/etc/step-ca
ExecStart=/usr/bin/step-ca config/ca.json --password-file password.txt
ExecReload=/bin/kill --signal HUP $MAINPID
Restart=on-failure
RestartSec=5
TimeoutStopSec=30
StartLimitInterval=30
StartLimitBurst=3

; Process capabilities & privileges
AmbientCapabilities=CAP_NET_BIND_SERVICE
CapabilityBoundingSet=CAP_NET_BIND_SERVICE
SecureBits=keep-caps
NoNewPrivileges=yes

; Sandboxing
ProtectSystem=full
ProtectHome=true
RestrictNamespaces=true
RestrictAddressFamilies=AF_UNIX AF_INET AF_INET6
PrivateTmp=true
PrivateDevices=true
ProtectClock=true
ProtectControlGroups=true
ProtectKernelTunables=true
ProtectKernelLogs=true
ProtectKernelModules=true
LockPersonality=true
RestrictSUIDSGID=true
RemoveIPC=true
RestrictRealtime=true
SystemCallFilter=@system-service
SystemCallArchitectures=native
MemoryDenyWriteExecute=true
ReadWriteDirectories=/etc/step-ca/db

[Install]
WantedBy=multi-user.target
`}</code></pre>
        <p parentName="li">{`(This file is also hosted `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/certificates/blob/master/systemd/step-ca.service"
          }}>{`on GitHub`}</a>{`)`}</p>
        <p parentName="li">{` Here are some notes on the security properties in this file:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`User`}</inlineCode>{` and `}<inlineCode parentName="p">{`Group`}</inlineCode>{` cause `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to run as a non-privileged user.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`AmbientCapabilities`}</inlineCode>{` allows the process to receive ambient capabilities.
`}<inlineCode parentName="p">{`CAP_NET_BIND_SERVICE`}</inlineCode>{` allows the process to bind to ports < 1024. See `}<a parentName="p" {...{
                "href": "https://man7.org/linux/man-pages/man7/capabilities.7.html"
              }}>{`capabiliites(7)`}</a>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`CapabilityBoundingSet`}</inlineCode>{` limits the set of capabilities the process can have.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`SecureBits`}</inlineCode>{` allows the service to keep its capabilities even after switching to the `}<inlineCode parentName="p">{`step`}</inlineCode>{` user.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`NoNewPrivileges`}</inlineCode>{` ensures no future privilege escalation by the process.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ProtectSystem`}</inlineCode>{` and `}<inlineCode parentName="p">{`ProtectHome`}</inlineCode>{` configure sandboxing via a read-only file system namespace dedicated to the process.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ProtectNamespaces`}</inlineCode>{` prevents the process from creating kernel namespaces.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`RestrictAddressFamilies`}</inlineCode>{` prevents the service from allocating esoteric sockets such as `}<inlineCode parentName="p">{`AF_PACKET`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`PrivateTmp`}</inlineCode>{` gives the service its own private `}<inlineCode parentName="p">{`/tmp`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`PrivateDevices`}</inlineCode>{` presents a very limited `}<inlineCode parentName="p">{`/dev`}</inlineCode>{` to the service.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`Protect*`}</inlineCode>{` limits access to system resources.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`LockPersonality`}</inlineCode>{` locks the process's execution domain.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`RestrictSUIDSGID`}</inlineCode>{` restricts setuid/setgid file creation.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`RemoveIPC`}</inlineCode>{` removes any IPC objects created by the service when it is stopped.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`RestrictRealtime`}</inlineCode>{` restricts real-time scheduling access.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`SystemCallFilter`}</inlineCode>{` defines an allow list of system calls the service can use.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`SystemCallArchitectures`}</inlineCode>{` restricts the service to only be able to call native system calls.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`MemoryDenyWriteExecute`}</inlineCode>{` prevents the service from creating writable-executable memory mappings.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ReadWriteDirectories`}</inlineCode>{` ensures that the process can write its state directories.`}</p>
            <p parentName="li">{`See `}<a parentName="p" {...{
                "href": "https://freedesktop.org/software/systemd/man/systemd.exec.html"
              }}>{`systemd.exec(5)`}</a>{`, `}<a parentName="p" {...{
                "href": "https://freedesktop.org/software/systemd/man/systemd.unit.html#"
              }}>{`systemd.unit(5)`}</a>{`,
and `}<a parentName="p" {...{
                "href": "https://freedesktop.org/software/systemd/man/systemd.service.html#"
              }}>{`systemd.service(5)`}</a>{` for more details.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enable and start the service.`}</p>
        <p parentName="li">{`The following are a few useful commands for
checking the status of your CA,
enabling it on system startup,
and starting your CA.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell-session",
            "metastring": "nocopy",
            "nocopy": true
          }}>{`# Rescan the systemd unit files
$ sudo systemctl daemon-reload

# Check the current status of the step-ca service
$ sudo systemctl status step-ca

# Enable and start the \`step-ca\` process
$ systemctl enable --now step-ca

# Follow the log messages for step-ca
$ journalctl --follow --unit=step-ca
`}</code></pre>
      </li>
    </ol>
    <h2>{`High Availability`}</h2>
    <Alert severity="warning" mdxType="Alert">
  <div> 
    <Code mdxType="Code">step-ca</Code> is built to scale horizontally. However, the creators
    and maintainers do not regularly test in an HA environment using multiple
    instances. You may run into issues we did not plan for. If this happens, please
    <Link href="https://github.com/smallstep/certificates/issues" mdxType="Link"> open an issue</Link>.
  </div>
    </Alert>
    <p>{`A few things to consider / implement when running multiple instances of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use a MySQL database.`}</strong>{` The default Badger database has no concurrency support.
The only integrated DB that can support multiple instances is MySQL.
See the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration#databases"
        }}>{`database documentation`}</a>{` to learn how to configure `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` for MySQL.`}</li>
      <li parentName="ul"><strong parentName="li">{`Respect concurrency limits.`}</strong>{` The ACME server has known concurrency limitations when using the same account to
manage multiple orders. The recommended temporary workaround is to generate
an ephemeral account keypair for each new ACME order, or to ensure that ACME
orders owned by the same account are managed serially. The issue tracking
this limitation can be found `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/certificates/issues/341"
        }}>{`here`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Synchronize `}<inlineCode parentName="strong">{`ca.json`}</inlineCode>{` across instances.`}</strong>{` `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` reads all of it's
configuration (and all of the provisioner configuration) from the `}<inlineCode parentName="li">{`ca.json`}</inlineCode>{` file
specified on the command line. If the `}<inlineCode parentName="li">{`ca.json`}</inlineCode>{` of one instance is modified
(either manually or using a command like `}<inlineCode parentName="li">{`step ca provisioner (add | remove)`}</inlineCode>{`)
the other instances will not pick up on this change until the `}<inlineCode parentName="li">{`ca.json`}</inlineCode>{` is
copied over to the correct location for each instance and the instance is
sent `}<inlineCode parentName="li">{`SIGHUP`}</inlineCode>{` or restarted. It's recommended to use a configuration management
tool (ansible, chef, salt, puppet, etc.) to synchronize `}<inlineCode parentName="li">{`ca.json`}</inlineCode>{` across instances.`}</li>
    </ul>
    <h2>{`Load balancing or proxying `}<inlineCode parentName="h2">{`step-ca`}</inlineCode>{` traffic`}</h2>
    <p>{`If you need to place a load balancer or reverse proxy downstream from the CA, we recommend using layer 4 (TCP)
load balancing or proxying (aka "TLS passthrough").`}</p>
    <p>{`We also recommend enabling `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners/#remote-provisioner-management"
      }}>{`remote provisioner management`}</a>{` so that your provisioner configuration can be shared by all `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` instances.`}</p>
    <p>{`Layer 7 proxying is `}<em parentName="p">{`not recommended`}</em>{`, becase the `}<inlineCode parentName="p">{`step`}</inlineCode>{` toolchain is built around TLS:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`step`}</inlineCode>{` expects to be able to perform a TLS handshake with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, using the CA's root certificate
to complete the trust chain.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Certificate renewal uses mutual TLS by default.
`}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` authenticates the client using the expiring certificate, in order to issue a new one.
Mutual TLS requires a direct, end-to-end TLS handshake between `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
(See `}<inlineCode parentName="p">{`step ca renew --help`}</inlineCode>{` for more details.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`By design, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` does not have an option to run in HTTP only.
Philosophically, we value perimeterless security and we believe people should use authenticated encryption (e.g. mutual TLS) everywhere.
Making mTLS easy, and helping people get away from the "perimeter security" anti-pattern, are motivating goals behind the project.`}</p>
        <p parentName="li">{`That said, lots of folks have legacy issues to contend with, some of these decisions are out of their control, and every threat model is different.
See `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/certificates/issues/246"
          }}>{`certificates#246`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <h3>{`Further Reading`}</h3>
    <ul>
      <li parentName="ul">{`Nginx has a `}<a parentName="li" {...{
          "href": "https://nginx.org/en/docs/stream/ngx_stream_core_module.html"
        }}>{`stream module`}</a>{` that allows it to pass TLS traffic directly to `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.
But it comes with a price: Unlike typical reverse proxy configurations, source IPs are not visible to `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` (there is no `}<inlineCode parentName="li">{`X-Forwarded-For`}</inlineCode>{` header), and traffic is also not logged to the nginx access log.
See `}<a parentName="li" {...{
          "href": "https://fedingo.com/how-to-configure-ssl-tls-passthrough-in-nginx/"
        }}>{`this blog post`}</a>{` for an example of TLS passthrough.`}</li>
      <li parentName="ul">{`Caddy doesn't natively support TLS passthrough, but there is an experimental `}<a parentName="li" {...{
          "href": "https://github.com/mholt/caddy-l4"
        }}><inlineCode parentName="a">{`caddy-l4`}</inlineCode></a>{` module that can do it.`}</li>
    </ul>
    <h2>{`Automate X.509 Certificate Lifecycle Management`}</h2>
    <p>{`We recommend automating certificate renewal when possible.
Renewal can be easily automated in many environments.
See our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/renewal"
      }}>{`Renewal`}</a>{` documentation for details.`}</p>
    <h2>{`X.509 Certificate Revocation`}</h2>
    <p>{`By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` uses passive revocation.
Certificates can be revoked using the `}<inlineCode parentName="p">{`step ca revoke`}</inlineCode>{` subcommand.
See our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/revocation"
      }}>{`Revocation`}</a>{` documentation for details.`}</p>
    <h2>{`Sane Cryptographic Defaults`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`step`}</inlineCode>{` ecosystem uses sane defaults so that you don't have to be a security
engineer to use our `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` safely. Our defaults align with best current
practices in the industry for using cryptographic primitives and higher order
abstractions, like JWTs.`}</p>
    <p>{`This section describes our defaults and explains the rationale behind them. Our
selections and guidance will change and evolve over time as security and
cryptography are constantly changing in response to real world pressures.`}</p>
    <h3>{`Tokens`}</h3>
    <p>{`We use JWTs (`}<a parentName="p" {...{
        "href": "https://jwt.io/"
      }}>{`JSON Web Tokens`}</a>{`) to prove authenticity and identity within the `}<inlineCode parentName="p">{`step`}</inlineCode>{` ecosystem. When configured well, JWTs are a great way to sign and encode data. It's easy to use JWTs insecurely, though, so you must be deliberate about how you validate and verify them (see `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7519#section-7"
      }}>{`RFC7519`}</a>{`).`}</p>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` produces JWTs that:`}</p>
    <ul>
      <li parentName="ul">{`are short-lived (5 minute lifespan)`}</li>
      <li parentName="ul">{`are one-time-use tokens (during the lifetime of the `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`) `}</li>
      <li parentName="ul">{`have a 1 minute clock drift leeway`}</li>
    </ul>
    <p>{`If you're using `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` JWTs in your code, be sure to `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7519#section-7"
      }}>{`verify and validate`}</a>{` every standard attribute of the JWT. `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/cli/crypto/jwt/verify/"
      }}><inlineCode parentName="a">{`step crypto jwt verify`}</inlineCode></a>{` can validate any JWT for you, and it follows the spec to the letter.`}</p>
    <h3>{`Key Types and Ciphers`}</h3>
    <p><strong parentName="p">{`Supported Key Types`}</strong>{`: ECDSA, EdDSA, and RSA`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Default Key Type`}</strong>{`: ECDSA`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Default Curve Bits`}</strong>{`: P-256`}</p>
    <p>{`We chose ECDSA keys because they offer better security and performance than RSA keys. At 256 bits, ECDSA keys provide 128 bits of security, and they are supported by most modern clients. `}</p>
    <p>{`More notes on the choice of key type:`}</p>
    <ul>
      <li parentName="ul">{`RSA keys are often chosen for compliance reasons.`}</li>
      <li parentName="ul">{`EdDSA keys are even smaller and faster than ECDSA keys. Were it supported by more clients, it would be the default.`}</li>
      <li parentName="ul">{`The NIST standard curves for ECDSA are hard to implement correctly, so there's concern that the implementations of them may have problems.`}</li>
      <li parentName="ul">{`If the NSA is in your threat model, you may not want to use ECDSA keys. The NSA has never published how they chose the magic numbers that drive ECDSA implementations.`}</li>
    </ul>
    <p><strong parentName="p">{`Default PEM Cipher`}</strong>{`: AES128`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Supported PEM Key Sizes`}</strong>{`: 128, 192, and 256 bits`}</p>
    <p>{`We've chosen the AES encryption algorithm for writing private keys to disk because it was the official choice of the Advanced Encryption Standard contest.`}</p>
    <p>{`All supported key sizes are considered to be unbreakable for the foreseeable future. We chose 128 bits as our default because the performance is better as compared to the greater key sizes, and because 128 bits are sufficient for most security needs.`}</p>
    <h3>{`X.509 Certificates`}</h3>
    <h4>{`Root CA Certificate`}</h4>
    <p>{`The Root CA certificate is generated once, when you run `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{`.`}</p>
    <p><strong parentName="p">{`Validity (10 year window)`}</strong></p>
    <ul>
      <li parentName="ul">{`Not Before: Now`}</li>
      <li parentName="ul">{`Not After: Now + 10 years`}</li>
    </ul>
    <p>{`A 10 year window is advisable until software and tools can be written for rotating the root certificate.`}</p>
    <p><strong parentName="p">{`Basic Constraints`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`CA: TRUE`}</p>
        <p parentName="li">{`The root certificate is a certificate authority and will be used to sign other Certificates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Path Length: 1`}</p>
        <p parentName="li">{`The Path Length constraint expresses the number of possible intermediate CA certificates in a path built from an end-entity certificate up to the CA certificate.`}</p>
        <p parentName="li">{`The default `}<inlineCode parentName="p">{`step`}</inlineCode>{` PKI has only one intermediate CA certificate between end-entity certificates and the root CA certificate.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Key Usage`}</strong></p>
    <p>{`Key Usage describes how the certificate can be used.`}</p>
    <ul>
      <li parentName="ul">{`Certificate Sign: indicates that our root public key will be used to verify a signature on certificates.`}</li>
      <li parentName="ul">{`CRL Sign: indicates that our root public key will be used to verify a signature on revocation information, such as CRL.`}</li>
    </ul>
    <h4>{`Intermediate CA Certificate`}</h4>
    <p>{`The Intermediate CA certificate is generated once, when you run `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{`. It is signed by the Root CA certificate.`}</p>
    <p>{`The Path Length of the intermediate certificate is `}<inlineCode parentName="p">{`0`}</inlineCode>{`. Otherwise it uses the same defaults as the root certificate.`}</p>
    <p>{`A Path Length of zero indicates that there can be no additional intermediary certificates in the path between the intermediate CA certificate and end-entity certificates.`}</p>
    <h4>{`Leaf (End Entity) Certificate`}</h4>
    <p>{`These are the certificates issued by the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server.`}</p>
    <p><strong parentName="p">{`Validity (24 hour window)`}</strong></p>
    <ul>
      <li parentName="ul">{`Not Before: Now`}</li>
      <li parentName="ul">{`Not After: Now + 24 hours`}</li>
    </ul>
    <p>{`The default is a 24hr window. This value is somewhat arbitrary. However, our goal is to have seamless end-entity certificate rotation. Rotating certificates frequently is a good security measure because it gives attackers very little time to form an attack and limits the usefulness of any single private key in the system.`}</p>
    <p>{`We will continue to work towards decreasing this window because we believe it significantly reduces the probability and effectiveness of any attack.`}</p>
    <p><strong parentName="p">{`Key Usage`}</strong></p>
    <p>{`Key Usage describes how the certificate can be used.`}</p>
    <ul>
      <li parentName="ul">{`Key Encipherment: indicates that a certificate will be used with a protocol that encrypts keys.`}</li>
      <li parentName="ul">{`Digital Signature: indicates that this public key may be used as a digital signature to support security services that enable entity authentication and data origin authentication with integrity.`}</li>
    </ul>
    <p><strong parentName="p">{`Extended Key Usage`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`TLS Web Server Authentication: certificate can be used as the server side certificate in the TLS protocol.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`TLS Web Client Authentication: certificate can be used as the client side certificate in the TLS protocol.`}</p>
      </li>
    </ul>
    <h3>{`TLS Defaults`}</h3>
    <p>{`These are the defaults used for communication between `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Min TLS Version`}</strong>{`: TLS 1.2`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Max TLS Version`}</strong>{`: TLS 1.2`}</p>
    <p>{`The PCI Security Standards Council required all payment processors and merchants to move to TLS 1.2 and above by June 30, 2018. By setting TLS 1.2 as the default for all TLS protocol negotiation, we encourage our users to adopt the same security conventions.`}</p>
    <p><strong parentName="p">{`Renegotiation`}</strong>{`: Never`}</p>
    <p>{`TLS renegotiation significantly complicates the state machine and has been the source of numerous, subtle security issues. Therefore, by default we disable it.`}</p>
    <h4>{`Default TLS Cipher Suites`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-go",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`[
  "TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305",
  "TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256",
]
`}</code></pre>
    <p>{`The default 'ciphersuites' are a list of two cipher combinations. For communication between services running `}<inlineCode parentName="p">{`step`}</inlineCode>{` there is no need for cipher suite negotiation. The server can specify a single cipher suite which the client is already known to support.`}</p>
    <p>{`Reasons for selecting `}<inlineCode parentName="p">{`TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul">{`ECDHE key exchange algorithm has perfect forward secrecy`}</li>
      <li parentName="ul">{`ECDSA has smaller keys and better performance than RSA`}</li>
      <li parentName="ul">{`CHACHA20 with POLY1305 is the cipher mode used by Google.`}</li>
      <li parentName="ul">{`CHACHA20's performance is better than GCM and CBC.`}</li>
    </ul>
    <p>{`The http2 spec requires the `}<inlineCode parentName="p">{`TLS_ECDHE_(RSA|ECDSA)_WITH_AES_128_GCM_SHA256`}</inlineCode>{` ciphersuite be accepted by the server, therefore it makes our list of default ciphersuites.`}</p>
    <h4>{`Approved TLS Cipher Suites`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-go",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`[
  "TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA",
  "TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA256",
  "TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256",
  "TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA",
  "TLS_ECDHE_ECDSA_WITH_AES_256_GCM_SHA384",
  "TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305",
  "TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA",
  "TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA256",
  "TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256",
  "TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA",
  "TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384",
  "TLS_ECDHE_RSA_WITH_CHACHA20_POLY1305",
]
`}</code></pre>
    <p>{`Above is a list of `}<inlineCode parentName="p">{`step`}</inlineCode>{`-approved cipher suites. Not all communication can be
resolved with `}<inlineCode parentName="p">{`step`}</inlineCode>{` TLS functionality. For those connections, the list of
server supported cipher suites must have more options in case older clients do
not support our favored cipher suite.`}</p>
    <p>{`Reasons for selecting these cipher suites can be found in the following
`}<a parentName="p" {...{
        "href": "https://github.com/ssllabs/research/wiki/SSL-and-TLS-Deployment-Best-Practices#23-use-secure-cipher-suites"
      }}>{`ssllabs article`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      